import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Asset, Directory, listAssetVariables } from "@api/graphql/types";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { ApplicationState } from "@redux/reducers";
import { Box, Typography, Grid, Button } from "@material-ui/core";
import { Intl } from "@i18n/Intl";
import styled from "styled-components";
import { Color } from "@theme/Theme";
import { Api } from "@api/Api";
import { Alert } from "@components/alert/Alert";
import { AppActions } from "@redux/actions/AppActions";
import { Skeleton } from "@material-ui/lab";
import * as _ from "lodash";
import { StyledAssetWrapper, StyledAssetInfoWrapper, AssetItem } from "@components/assets/AssetItem";
import { CustomEventList } from "@utils/Events";
import { UpdateAssetDialog } from "@components/dialogs/UpdateAssetDialog";
import { DeleteAssetDialog } from "@components/dialogs/DeleteAssetDialog";
import { MoveAssetsDialog } from "@components/dialogs/MoveAssetsDialog";

interface ComponentProps {
    innerRef?: (ref: HTMLDivElement | null) => void;
}

interface ReduxProps {
    currentDirectory: Directory | null;
    selectedAssets: Asset[];
    options: listAssetVariables;
}

type Props = ComponentProps & ReduxProps & RouteComponentProps & DispatchProp;

interface State {
    isLoading: boolean;
    assets: Asset[];
    page: number;
    lastPage: number;
    updateAssetModalOpened: boolean;
    updateAssetModalAsset: Asset | null;
    deleteAssetModalOpened: boolean;
    deleteAssetModalAsset: Asset | null;
    moveAssetModalOpened: boolean;
    moveAssetModalAssets: Asset[];
}

class AssetsComponent extends React.Component<Props, State> {
    public state: Readonly<State> = {
        isLoading: true,
        assets: [],
        page: 1,
        lastPage: 1,
        updateAssetModalOpened: false,
        updateAssetModalAsset: null,
        deleteAssetModalOpened: false,
        deleteAssetModalAsset: null,
        moveAssetModalOpened: false,
        moveAssetModalAssets: [],
    };

    public componentDidMount() {
        this.fetchAssets();
        window.addEventListener(CustomEventList.refreshAssets, () => this.fetchAssets());
        window.addEventListener(CustomEventList.selectAllAssets, () => this.selectAllAssets());
        window.addEventListener(CustomEventList.moveSelectedAssets, () =>
            this.toggleAssetMove(this.props.selectedAssets)
        );
    }

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if (
            prevProps.currentDirectory !== this.props.currentDirectory ||
            !_.isEqual(prevProps.options, this.props.options)
        ) {
            this.fetchAssets(true);
        }
    }

    public componentWillUnmount(): void {
        window.removeEventListener(CustomEventList.refreshAssets, () => this.fetchAssets(true));
        window.removeEventListener(CustomEventList.selectAllAssets, () => this.selectAllAssets());
        window.removeEventListener(CustomEventList.moveSelectedAssets, () =>
            this.toggleAssetMove(this.props.selectedAssets)
        );
    }

    private readonly fetchAssets = (refresh = false): void => {
        this.setState({ isLoading: true }, async () => {
            try {
                const response = await Api.listAsset({
                    ...this.props.options,
                    page: this.state.page,
                });

                this.setState({
                    isLoading: false,
                    assets:
                        this.state.page > 1 && !refresh ? { ...this.state.assets, ...response.data } : response.data,
                    page: response.paginatorInfo.currentPage,
                    lastPage: response.paginatorInfo.lastPage,
                });
            } catch (error) {
                Alert.error({ title: Intl.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    private readonly toggleAssetUpdate = (asset?: Asset): void => {
        this.setState({
            updateAssetModalOpened: !!asset,
            updateAssetModalAsset: asset || null,
        });
    };

    private readonly toggleAssetDelete = (asset?: Asset): void => {
        this.setState({
            deleteAssetModalOpened: !!asset,
            deleteAssetModalAsset: asset || null,
        });
    };

    private readonly toggleAssetMove = (assets: Asset[]): void => {
        this.setState({
            moveAssetModalOpened: assets.length > 0,
            moveAssetModalAssets: assets,
        });
    };

    private readonly selectAllAssets = (): void => {
        this.props.dispatch(AppActions.setSelectedAssets(this.state.assets));
    };

    private readonly loadMoreAssets = (): void => {
        if (this.state.page === this.state.lastPage) {
            return;
        }
        this.setState({
            page: this.state.page + 1,
        });
    };

    private readonly renderLoadingAssets = (): React.ReactElement[] => {
        return new Array(5).fill(1).map((_value, key) => {
            return (
                <Grid item xs={12} sm={4} md={2} key={`loadingAsset-${key}`}>
                    <StyledAssetWrapper selected={false}>
                        <Skeleton variant="rect" width={"100%"} height={200} />
                        <StyledAssetInfoWrapper>
                            <Skeleton variant="text" height={30} width={`${_.random(30, 80)}%`} />
                        </StyledAssetInfoWrapper>
                    </StyledAssetWrapper>
                </Grid>
            );
        });
    };

    private readonly renderAssets = (assets: Asset[]): React.ReactElement[] => {
        return assets.map((asset, key) => {
            return (
                <Grid item xs={12} sm={4} md={2} key={`asset-${asset.id}-${key}`}>
                    <AssetItem
                        selected={this.props.selectedAssets.some(item => item.id === asset.id)}
                        onClick={() => this.props.dispatch(AppActions.toggleSelectedAsset(asset))}
                        onMove={() => this.toggleAssetMove([asset])}
                        onUpdate={() => this.toggleAssetUpdate(asset)}
                        onDelete={() => this.toggleAssetDelete(asset)}
                        asset={asset}
                    />
                </Grid>
            );
        });
    };

    public render() {
        const {
            isLoading,
            assets,
            updateAssetModalOpened,
            updateAssetModalAsset,
            deleteAssetModalOpened,
            deleteAssetModalAsset,
            moveAssetModalOpened,
            moveAssetModalAssets,
        } = this.state;
        return (
            <StyledAssetsWrapper ref={this.props.innerRef}>
                {assets.length > 0 && (
                    <Box display="block">
                        <Typography variant="subtitle1">
                            <StyledTitle>{Intl.formatMessage({ id: "components.assets.title" })}</StyledTitle>
                        </Typography>
                    </Box>
                )}
                <Grid container spacing={3}>
                    {this.renderAssets(assets)}
                    {isLoading && this.renderLoadingAssets()}
                </Grid>
                {this.state.page < this.state.lastPage && (
                    <StyledButtonWrapper>
                        <Button fullWidth={true} variant="contained" color="primary" onClick={this.loadMoreAssets}>
                            {Intl.formatMessage({ id: "components.assets.loadMore" })}
                        </Button>
                    </StyledButtonWrapper>
                )}
                {updateAssetModalOpened && !!updateAssetModalAsset && (
                    <UpdateAssetDialog
                        opened={updateAssetModalOpened}
                        asset={updateAssetModalAsset}
                        onClose={() => this.toggleAssetUpdate()}
                    />
                )}
                {deleteAssetModalOpened && !!deleteAssetModalAsset && (
                    <DeleteAssetDialog
                        opened={deleteAssetModalOpened}
                        asset={deleteAssetModalAsset}
                        onClose={() => this.toggleAssetDelete()}
                    />
                )}
                {moveAssetModalOpened && moveAssetModalAssets.length > 0 && (
                    <MoveAssetsDialog
                        opened={moveAssetModalOpened}
                        assets={moveAssetModalAssets}
                        onClose={() => this.toggleAssetMove([])}
                    />
                )}
            </StyledAssetsWrapper>
        );
    }
}

const StyledTitle = styled.span`
    margin-left: 16px;
    color: ${Color("grey", 600)};
`;

const StyledAssetsWrapper = styled.div`
    margin-top: 1.5rem;
`;

const StyledButtonWrapper = styled.div`
    margin: 1rem 0;
`;

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (
    state: ApplicationState
): ReduxProps => {
    return {
        currentDirectory: state.app.currentDirectory,
        selectedAssets: state.app.selectedAssets,
        options: state.app.assetOptions,
    };
};

const Assets = withRouter(connect(mapStateToProps)(AssetsComponent));

export { Assets };
