import { AppPath } from "@pages/paths";
import { ApplicationState } from "@redux/reducers";
import React from "react";
import { MapStateToProps, connect, DispatchProp } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { IncomingEvent, OutgoingEvent, sendPMEvent } from "@utils/EmbeddedUtils";
import { batchActions } from "redux-batched-actions";
import { AppConfigurationType } from "@redux/reducers/AppReducer";
import { AppActions } from "@redux/actions/AppActions";
import { LoadingOverlay } from "@components/LoadingOverlay";

enum PossibleInitError {
    NoTokenProvided,
}

type StateType = {
    errorType: PossibleInitError | null;
};

type ReduxProps = {};
type Props = DispatchProp & RouteComponentProps;

class EmbeddedStartPageComponent extends React.Component<Props, StateType> {
    state: StateType = {
        errorType: null,
    };

    componentDidMount() {
        window.addEventListener("message", this.embeddedMessageListener);
        sendPMEvent(OutgoingEvent.LoadComplete);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.embeddedMessageListener);
    }

    private embeddedMessageListener = (messageEvent: MessageEvent) => {
        const receivedMessage = messageEvent.data || "{}";
        console.log({ receivedMessage });
        try {
            const { type, event, ...otherParams } = JSON.parse(receivedMessage);
            if (type !== "medlibmsg") {
                return;
            }
            switch (event as IncomingEvent) {
                case IncomingEvent.InitOptions: {
                    this.setupInitialOptions(otherParams.opts);
                    break;
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    private setupInitialOptions = (opts: Partial<AppConfigurationType>) => {
        if (!opts.embedAuthToken) {
            this.setState({ errorType: PossibleInitError.NoTokenProvided });
            return;
        }
        this.props.dispatch(
            batchActions([AppActions.setAppConfiguration(opts, true), AppActions.setAuthToken(opts.embedAuthToken)])
        );
        this.props.history.replace(AppPath.Root);
    };

    public render(): JSX.Element {
        switch (this.state.errorType) {
            default: {
                return <LoadingOverlay />;
            }
        }
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (): ReduxProps => {
    return {};
};

export const EmbeddedStartPage = connect(mapStateToProps)(EmbeddedStartPageComponent);
