export const localeHu = {
    common: {
        loading: "Betöltés...",
        back: "Vissza",
        cancel: "Mégsem",
        save: "Mentés",
        delete: "Törlés",
        createDirectory: "Mappa létrehozása",
        upload: "Feltöltés",
        searchPlaceholder: "Keresés...",
    },
    components: {
        directories: {
            title: "Mappák",
        },
        assets: {
            title: "Fájlok",
            loadMore: "Több fájl betöltése...",
        },
        directoryContext: {
            open: "Mappa megnyitása",
            edit: "Mappa szerkesztése",
            delete: "Mappa törlése",
        },
        assetContext: {
            unselect: "Fájl kiválasztásának törlése",
            select: "Fájl kiválasztása",
            edit: "Fájl szerkesztése",
            move: "Fájl áthelyezése",
            delete: "Fájl törlése",
            copyFileName: "Fájlnév vágólapra másolása",
        },
        createDirectoryDialog: {
            title: "Mappa létrehozása",
            form: {
                name: "Mappa neve",
            },
            success: "A mappa sikeresen létrehozásra került!",
        },
        updateDirectoryDialog: {
            title: "Mappa módosítása",
            form: {
                name: "Mappa neve",
            },
            success: "A mappa sikeresen frissítésre került!",
        },
        deleteDirectoryDialog: {
            title: "Biztosan törölni szeretné az alábbi mappát?",
            description:
                "Ezzel a folyamattal a(z) <b>{name}</b> nevű mappája törlésre kerül. Ez a művelet nem vonható vissza!",
            success: "A mappa sikeresen törlésre került!",
        },
        updateAssetDialog: {
            title: "Fájl módosítása",
            form: {
                name: "Fájl neve",
            },
            success: "A fájl sikeresen frissítésre került!",
        },

        deleteAssetDialog: {
            title: "Biztosan törölni szeretné az alábbi fájlt?",
            description:
                "Ezzel a folyamattal a(z) <b>{name}</b> nevű fájlja törlésre kerül. Ez a művelet nem vonható vissza!",
            success: "A fájl sikeresen törlésre került!",
        },
        uploadAssetDialog: {
            title: "Fájl(ok) feltöltése",
        },
        dropzone: {
            placeholder: "Kattintson, vagy húzzon fájlokat ide!",
        },
        moveAssetsDialog: {
            title: "Fájlok áthelyezése",
            description: "Az alábbi fájl(ok) kerülnek áthelyezésre:",
            success: "A fájl(ok) áthelyezése sikeres!",
        },
        floatingBar: {
            fileSelected: "fájl kiválasztva",
            moveFiles: "Fájlok áthelyezése",
            selectAll: "Összes kijelölése",
            clearSelect: "Kijelölés törlése",
            insert: "Beillesztés",
        },
        login: {
            title: "Bejelentkezés",
            token: "Token",
            auth: "Authentikáció",
        },
    },
    error: {
        validation: {
            required: "A mező kitöltése kötelező!",
        },
        api: {
            INTERNAL_SERVER_ERROR: "Ismeretlen szerverhiba!",
            UNKNOWN: "Ismeretlen szerverhiba!",
        },
    },
};
