import React from "react";
import { Asset } from "@api/graphql/types";
import { DispatchProp, MapStateToProps, connect } from "react-redux";
import { Paper, Grid, Typography, Divider, Button } from "@material-ui/core";
import { ApplicationState } from "@redux/reducers";
import styled from "styled-components";
import { SelectAll, BorderClear, MoveToInbox, InsertPhotoRounded } from "@material-ui/icons";
import { AppActions } from "@redux/actions/AppActions";
import { CustomEventList } from "@utils/Events";
import { Intl } from "@i18n/Intl";
import { OutgoingEvent, sendPMEvent } from "@utils/EmbeddedUtils";

interface ComponentProps {
    isEmbeddedView: boolean;
    maxSelectableAsset: number;
    frameless: boolean;
}

interface ReduxProps {
    selectedAssets: Asset[];
}

type Props = ComponentProps & ReduxProps & DispatchProp;

class FloatingBarComponent extends React.Component<Props> {
    public render(): React.ReactElement {
        const { selectedAssets } = this.props;
        if (selectedAssets.length === 0) {
            return <></>;
        }
        return (
            <StyledFloatingBar>
                <Grid container spacing={3} alignItems={"center"}>
                    <Grid item>
                        <Typography variant="subtitle1">
                            <b>{selectedAssets.length}</b>{" "}
                            {Intl.formatMessage({ id: "components.floatingBar.fileSelected" })}
                        </Typography>
                    </Grid>
                    <StyledDivider orientation={"vertical"} flexItem={true} />
                    <Grid item>
                        <Button
                            startIcon={<MoveToInbox />}
                            onClick={() => window.dispatchEvent(new CustomEvent(CustomEventList.moveSelectedAssets))}
                        >
                            {Intl.formatMessage({ id: "components.floatingBar.moveFiles" })}
                        </Button>
                    </Grid>
                    {this.props.maxSelectableAsset === 0 && (
                        <>
                            <StyledDivider orientation={"vertical"} flexItem={true} />
                            <Grid item>
                                <Button
                                    startIcon={<SelectAll />}
                                    onClick={() =>
                                        window.dispatchEvent(new CustomEvent(CustomEventList.selectAllAssets))
                                    }
                                >
                                    {Intl.formatMessage({ id: "components.floatingBar.selectAll" })}
                                </Button>
                            </Grid>
                        </>
                    )}
                    <StyledDivider orientation={"vertical"} flexItem={true} />
                    <Grid item>
                        <Button
                            startIcon={<BorderClear />}
                            onClick={() => this.props.dispatch(AppActions.clearSelectedAssets())}
                        >
                            {Intl.formatMessage({ id: "components.floatingBar.clearSelect" })}
                        </Button>
                    </Grid>
                    {this.props.isEmbeddedView && !this.props.frameless && (
                        <>
                            <StyledDivider orientation={"vertical"} flexItem={true} />
                            <Grid item>
                                <Button
                                    startIcon={<InsertPhotoRounded />}
                                    onClick={() => {
                                        sendPMEvent(OutgoingEvent.ItemsSelected, {
                                            assets: this.props.selectedAssets.map(asset => asset.file_name),
                                        });
                                    }}
                                >
                                    {Intl.formatMessage({ id: "components.floatingBar.insert" })}
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </StyledFloatingBar>
        );
    }
}

const StyledFloatingBar = styled(Paper)`
    position: absolute;
    width: fit-content !important;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    z-index: 1100;
    padding: 1rem;
`;

const StyledDivider = styled(Divider)`
    margin: 0.75rem 0 !important;
`;

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (
    state: ApplicationState
): ReduxProps => {
    return {
        selectedAssets: state.app.selectedAssets,
    };
};

const FloatingBar = connect(mapStateToProps)(FloatingBarComponent);

export { FloatingBar };
