import { Asset, Directory, listAssetVariables } from "@api/graphql/types";
import { AppConfigurationType } from "@redux/reducers/AppReducer";

export enum AppActionType {
    setCurrentDirectory = "@app/setCurrentDirectory",
    setAppConfiguration = "@app/setAppConfiguration",
    toggleSelectedAsset = "@app/toggleSelectedAsset",
    setSelectedAssets = "@app/setSelectedAssets",
    clearSelectedAssets = "@app/clearSelectedAssets",
    setAssetOptions = "@appState/setAssetOptions",
    clearAssetOptions = "@appState/clearAssetOptions",
    setAuthToken = "@appState/setAuthToken",
}

interface SetCurrentDirectoryAction {
    type: AppActionType.setCurrentDirectory;
    directory: Directory | null;
}

interface SetAppConfiguration {
    type: AppActionType.setAppConfiguration;
    options: AppConfigurationType;
    isUpdate: boolean;
}

interface ToggleSelectedAsset {
    type: AppActionType.toggleSelectedAsset;
    asset: Asset;
}

interface SetSelectedAssets {
    type: AppActionType.setSelectedAssets;
    assets: Asset[];
}

interface ClearSelectedAssets {
    type: AppActionType.clearSelectedAssets;
}

interface SetAssetOptions {
    type: AppActionType.setAssetOptions;
    options: listAssetVariables;
}

interface ClearAssetOptions {
    type: AppActionType.clearAssetOptions;
}

interface SetAuthToken {
    type: AppActionType.setAuthToken;
    token: string;
}

export class AppActions {
    public static setCurrentDirectory(directory: Directory | null): SetCurrentDirectoryAction {
        return { type: AppActionType.setCurrentDirectory, directory };
    }

    public static setAppConfiguration(options: AppConfigurationType, isUpdate: boolean): SetAppConfiguration {
        return { type: AppActionType.setAppConfiguration, options, isUpdate };
    }

    public static toggleSelectedAsset(asset: Asset): ToggleSelectedAsset {
        return { type: AppActionType.toggleSelectedAsset, asset };
    }

    public static setSelectedAssets(assets: Asset[]): SetSelectedAssets {
        return { type: AppActionType.setSelectedAssets, assets };
    }

    public static clearSelectedAssets(): ClearSelectedAssets {
        return { type: AppActionType.clearSelectedAssets };
    }

    public static setAssetOptions(options: listAssetVariables): SetAssetOptions {
        return { type: AppActionType.setAssetOptions, options };
    }

    public static clearAssetOptions(): ClearAssetOptions {
        return { type: AppActionType.clearAssetOptions };
    }

    public static setAuthToken(token: string): SetAuthToken {
        return { type: AppActionType.setAuthToken, token };
    }
}

export type AppAction =
    | SetCurrentDirectoryAction
    | SetAppConfiguration
    | ToggleSelectedAsset
    | SetSelectedAssets
    | ClearSelectedAssets
    | SetAssetOptions
    | ClearAssetOptions
    | SetAuthToken;
