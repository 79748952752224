import React from "react";
import { Directory } from "@api/graphql/types";
import { MapStateToProps, connect } from "react-redux";
import { ApplicationState } from "@redux/reducers";
import { CustomEventList } from "@utils/Events";
import { Alert } from "@components/alert/Alert";
import { Intl } from "@i18n/Intl";
import { Api } from "@api/Api";
import { Dialog, DialogTitle, DialogContent, Typography, LinearProgress, Box } from "@material-ui/core";
import styled from "styled-components";
import { Color } from "@theme/Theme";
import Dropzone from "react-dropzone";
import { ValidatorConstants } from "@utils/Validator";

interface ComponentProps {
    opened: boolean;
    onClose?: () => void;
}

interface ReduxProps {
    currentDirectory: Directory | null;
}

type Props = ComponentProps & ReduxProps;

type UploadStatus = {
    key: number;
    progress: number;
    isUploaded: boolean;
    error?: boolean;
};

interface State {
    uploads: UploadStatus[];
}

class UploadAssetDialogComponent extends React.Component<Props, State> {
    public state: State = {
        uploads: [],
    };

    public componentDidUpdate() {
        if (this.state.uploads.length > 0 && this.state.uploads.filter(upload => !upload.isUploaded).length === 0) {
            this.setState({ uploads: [] }, () => {
                window.dispatchEvent(new CustomEvent(CustomEventList.refreshAssets));
            });
        }
    }

    private readonly onFileSelected = async (files: File[]) => {
        if (files.length < 1) {
            return;
        }

        this.setState(
            {
                uploads: files.map((file, key) => {
                    return {
                        key: key,
                        progress: 0,
                        isUploaded: false,
                    };
                }),
            },
            () => {
                files.forEach((file, key) => this.onFileUpload(file, key));
            }
        );

        this.props.onClose && this.props.onClose();
    };

    private readonly onProgressChange = (progress: number, key: number): void => {
        const currentUploads = this.state.uploads;
        currentUploads[key].progress = progress;
        this.setState({ uploads: currentUploads });
    };

    private readonly onFileUpload = async (file: File, key: number) => {
        try {
            await Api.uploadAsset(
                file.name,
                file,
                (progress: number) => {
                    this.onProgressChange(progress, key);
                },
                this.props.currentDirectory?.id
            );
            this.onUploadReady(key);
        } catch (error) {
            this.onUploadReady(key, true);
            Alert.error({ title: Intl.getMessageFromError(error) });
        }
    };

    private readonly onUploadReady = (key: number, error?: boolean) => {
        const currentUploads = this.state.uploads;
        currentUploads[currentUploads.findIndex(upload => upload.key === key)].isUploaded = true;
        currentUploads[currentUploads.findIndex(upload => upload.key === key)].error = !!error;
        this.setState({ uploads: currentUploads }, () => {
            setTimeout(() => {
                this.setState({
                    uploads: this.state.uploads.filter(upload => upload.key !== key),
                });
            }, 5000);
        });
    };

    private readonly renderUploads = (uploads: UploadStatus[]): React.ReactElement[] => {
        return uploads.map((upload: UploadStatus, key: number) => {
            return (
                <Box display="flex" alignItems="center" key={`uploadStatus-${key}`}>
                    <Box width="100%" mr={1}>
                        {!upload.error ? (
                            upload.isUploaded ? (
                                <StyledLinearProgressSuccess variant="determinate" value={upload.progress} />
                            ) : (
                                <StyledLinearProgress variant="determinate" value={upload.progress} />
                            )
                        ) : (
                            <StyledLinearProgressError variant="determinate" value={upload.progress} />
                        )}
                    </Box>
                    <Box minWidth={35}>
                        <Typography variant="body2" color="textSecondary">
                            {Math.floor(upload.progress)}%
                        </Typography>
                    </Box>
                </Box>
            );
        });
    };

    public render(): React.ReactElement {
        const { opened, onClose } = this.props;

        return (
            <Dialog open={opened} onClose={onClose} fullWidth={true} maxWidth={"sm"}>
                <DialogTitle>{Intl.formatMessage({ id: "components.uploadAssetDialog.title" })}</DialogTitle>
                <DialogContent>
                    <Dropzone onDrop={this.onFileSelected} accept={ValidatorConstants.ACCEPTED_IMAGES}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <DropzoneContainer {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Typography variant="subtitle1">
                                        {Intl.formatMessage({ id: "components.dropzone.placeholder" })}
                                    </Typography>
                                </DropzoneContainer>
                            </section>
                        )}
                    </Dropzone>
                    {this.renderUploads(this.state.uploads)}
                </DialogContent>
            </Dialog>
        );
    }
}

const StyledLinearProgress = styled(LinearProgress)`
    background-color: ${Color("blue", 100)} !important;

    .MuiLinearProgress-bar1Determinate {
        background-color: ${Color("blue", 500)};
    }
`;

const StyledLinearProgressSuccess = styled(LinearProgress)`
    background-color: ${Color("green", 100)} !important;

    .MuiLinearProgress-bar1Determinate {
        background-color: ${Color("green", 500)};
    }
`;

const StyledLinearProgressError = styled(LinearProgress)`
    background-color: ${Color("red", 100)} !important;

    .MuiLinearProgress-bar1Determinate {
        background-color: ${Color("red", 500)} !important;
    }
`;

const DropzoneContainer = styled.div`
    width: 100%;
    height: 100px;
    border-radius: 3px;
    border: 1px solid ${Color("grey", 500)};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-in-out all 300ms;
    margin-bottom: 1rem;

    &:hover {
        background-color: ${Color("grey", 300)};
        cursor: pointer;
    }
`;

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (
    state: ApplicationState
): ReduxProps => {
    return {
        currentDirectory: state.app.currentDirectory,
    };
};

const UploadAssetDialog = connect(mapStateToProps)(UploadAssetDialogComponent);

export { UploadAssetDialog };
