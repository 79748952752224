import gql from "graphql-tag";
import { DocumentNode } from "graphql";
import { Fragments } from "@api/graphql/queries/Fragments";

class Queries {
    public static readonly listDirectory: DocumentNode = gql`
        query listDirectory($filters: DirectoryFilters, $sortBy: DirectoryOrder, $first: Int!, $page: Int) {
            listDirectory(filters: $filters, sortBy: $sortBy, first: $first, page: $page) {
                paginatorInfo {
                    ...PaginatorInfo
                }
                data {
                    ...Directory
                }
            }
        }
        ${Fragments.paginatorInfo}
        ${Fragments.directory}
    `;

    public static readonly directory: DocumentNode = gql`
        query directory($id: String!) {
            directory(id: $id) {
                ...Directory
            }
        }
        ${Fragments.directory}
    `;

    public static readonly listAsset: DocumentNode = gql`
        query listAsset($filters: AssetFilters, $sortBy: AssetOrder, $first: Int!, $page: Int) {
            listAsset(filters: $filters, sortBy: $sortBy, first: $first, page: $page) {
                paginatorInfo {
                    ...PaginatorInfo
                }
                data {
                    ...Asset
                }
            }
        }
        ${Fragments.paginatorInfo}
        ${Fragments.asset}
    `;
}

export { Queries };
