class MimeType {
    public static readonly mimeTypeExtension: { [index: string]: string } = {
        "image/jpeg": "jpg",
        "image/jpg": "jpg",
        "image/png": "png",
    };

    public static readonly extension = (mimeType: string): string | undefined => {
        if (MimeType.mimeTypeExtension[mimeType]) {
            return MimeType.mimeTypeExtension[mimeType];
        }
        return undefined;
    };
}

export { MimeType };
