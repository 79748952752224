import React from "react";
import { Directory } from "@api/graphql/types";
import { ListItem, ListItemText, Collapse, IconButton, ListItemSecondaryAction } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { DirectoryList } from "@components/directory/DirectoryList";

type Props = {
    directory: Directory;
    onClick?: (directory: Directory) => void;
    selected?: string;
};

interface State {
    open: boolean;
}

export class DirectoryListItem extends React.Component<Props, State> {
    public state: Readonly<State> = {
        open: false,
    };

    private readonly toggleSubmenu = (): void => {
        this.setState({ open: !this.state.open });
    };

    public render(): React.ReactElement {
        const { directory, onClick, selected } = this.props;
        const { open } = this.state;
        console.log({ children: directory.children, length: directory.children.length > 0 });
        return (
            <>
                <ListItem button onClick={() => onClick && onClick(directory)} selected={directory.id === selected}>
                    <ListItemText>{directory.name}</ListItemText>
                    {directory.children.length > 0 && (
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => this.toggleSubmenu()}>
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </IconButton>
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
                {open && (
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <DirectoryList parentId={directory.id} onClick={onClick} selected={selected} />
                    </Collapse>
                )}
            </>
        );
    }
}
