import React from "react";
import styled from "styled-components";
import { Color } from "@theme/Theme";
import { Folder, Edit, FolderOpen, Delete } from "@material-ui/icons";
import { Typography, Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Intl } from "@i18n/Intl";

type Props = {
    onClick?: () => void;
    title: string;
    deletable: boolean;
    onDelete: () => void;
    onUpdate: () => void;
};

interface State {
    contextMenuOpened: boolean;
    contextMenuX: number;
    contextMenuY: number;
}

class DirectoryItem extends React.Component<Props> {
    private directoryRef: HTMLDivElement | null = null;

    public state: Readonly<State> = {
        contextMenuOpened: false,
        contextMenuX: 0,
        contextMenuY: 0,
    };

    private readonly onUpdate = (): void => {
        this.setState(
            {
                contextMenuOpened: false,
            },
            () => {
                this.props.onUpdate();
            }
        );
    };

    private readonly onDelete = (): void => {
        this.setState(
            {
                contextMenuOpened: false,
            },
            () => {
                this.props.onDelete();
            }
        );
    };

    private readonly onOpen = (): void => {
        this.setState(
            {
                contextMenuOpened: false,
            },
            () => {
                this.props.onClick && this.props.onClick();
            }
        );
    };

    private readonly onOpenContextMenu = (e: React.MouseEvent<HTMLDivElement>): void => {
        e.preventDefault();
        if (e.currentTarget !== this.directoryRef) {
            return;
        }

        if (this.state.contextMenuOpened) {
            this.setState({ contextMenuOpened: false }, () => {
                setTimeout(() => {
                    this.setState({
                        contextMenuOpened: true,
                        contextMenuX: e.clientX - 2,
                        contextMenuY: e.clientY - 4,
                    });
                }, 200);
            });
        } else {
            this.setState({
                contextMenuOpened: true,
                contextMenuX: e.clientX - 2,
                contextMenuY: e.clientY - 4,
            });
        }
    };

    public render(): React.ReactElement {
        const { title, ...otherProps } = this.props;
        const { contextMenuOpened, contextMenuX, contextMenuY } = this.state;

        return (
            <>
                <StyledDirectoryWrapper
                    {...otherProps}
                    ref={ref => {
                        this.directoryRef = ref;
                    }}
                    onContextMenu={this.onOpenContextMenu}
                >
                    <StyledDirectoryIconWrapper>
                        <Folder />
                    </StyledDirectoryIconWrapper>
                    <StyledDirectoryTitleWrapper>
                        <Typography variant="body2">{title}</Typography>
                    </StyledDirectoryTitleWrapper>
                </StyledDirectoryWrapper>
                <Menu
                    keepMounted={true}
                    open={contextMenuOpened}
                    onClose={() => this.setState({ contextMenuOpened: false })}
                    anchorReference="anchorPosition"
                    anchorPosition={{
                        left: contextMenuX,
                        top: contextMenuY,
                    }}
                >
                    <MenuItem onClick={() => this.onOpen()}>
                        <ListItemIcon>
                            <FolderOpen />
                        </ListItemIcon>
                        <ListItemText>{Intl.formatMessage({ id: "components.directoryContext.open" })}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => this.onUpdate()}>
                        <ListItemIcon>
                            <Edit />
                        </ListItemIcon>
                        <ListItemText>{Intl.formatMessage({ id: "components.directoryContext.edit" })}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => this.onDelete()} disabled={!this.props.deletable}>
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText>{Intl.formatMessage({ id: "components.directoryContext.delete" })}</ListItemText>
                    </MenuItem>
                </Menu>
            </>
        );
    }
}

const StyledDirectoryIconWrapper = styled.div`
    height: 24px;
    width: 24px;
    margin: 0 16px;
    color: ${Color("grey", 600)};
`;

const StyledDirectoryTitleWrapper = styled.div`
    display: flex;
    align-content: center;
`;

const StyledDirectoryWrapper = styled.div<{ selected?: boolean }>`
    display: flex;
    align-items: center;
    border: 1px solid ${Color("grey", 400)};
    height: 48px;
    border-radius: 6px;
    transition: background-color 100ms ease-in-out;
    background-color: ${props => (props.selected ? Color("blue", 50) : "transparent")};

    ${StyledDirectoryTitleWrapper} {
        color: ${props => (props.selected ? Color("blue", 700) : Color("grey", 800))};
    }

    &:hover {
        cursor: pointer;
    }
`;

export { DirectoryItem };
