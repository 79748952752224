export enum FileSize {
    B = 1,
    KB = 1024 * FileSize.B,
    MB = 1024 * FileSize.KB,
    GB = 1024 * FileSize.MB,
}

export class FileSizeUtils {
    public static readonly formatSize = (fileSizeInBytes: number, newSize: FileSize): number => {
        return Math.round((fileSizeInBytes / newSize) * 100) / 100;
    };

    public static readonly formatHumanSize = (bytes: number, si = true, dp = 1): string => {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + " B";
        }

        const units = si
            ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
            : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        return bytes.toFixed(dp) + " " + units[u];
    };
}
