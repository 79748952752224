import React from "react";

import { Backdrop, CircularProgress } from "@material-ui/core";

class LoadingOverlay extends React.PureComponent {
    public render(): React.ReactElement {
        return (
            <Backdrop open={true} invisible={true}>
                <CircularProgress color="primary" size={100} />
            </Backdrop>
        );
    }
}

export { LoadingOverlay };
