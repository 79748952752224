import gql from "graphql-tag";
import { DocumentNode } from "graphql";

import { Fragments } from "./Fragments";

export class Mutations {
    public static readonly uploadAsset: DocumentNode = gql`
        mutation uploadAsset($title: String!, $file: Upload!, $directory_id: String) {
            uploadAsset(title: $title, file: $file, directory_id: $directory_id) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly updateAsset: DocumentNode = gql`
        mutation updateAsset($id: String!, $title: String!) {
            updateAsset(id: $id, title: $title) {
                ...Asset
            }
        }
        ${Fragments.asset}
    `;

    public static readonly deleteAsset: DocumentNode = gql`
        mutation deleteAsset($id: String!) {
            deleteAsset(id: $id)
        }
    `;

    public static readonly deleteAssets: DocumentNode = gql`
        mutation deleteAssets($ids: [String!]!) {
            deleteAssets(ids: $ids)
        }
    `;

    public static readonly createDirectory: DocumentNode = gql`
        mutation createDirectory($input: CreateDirectoryInput!) {
            createDirectory(input: $input) {
                ...Directory
            }
        }
        ${Fragments.directory}
    `;

    public static readonly updateDirectory: DocumentNode = gql`
        mutation updateDirectory($id: String!, $input: UpdateDirectoryInput!) {
            updateDirectory(id: $id, input: $input) {
                ...Directory
            }
        }
        ${Fragments.directory}
    `;

    public static readonly deleteDirectory: DocumentNode = gql`
        mutation deleteDirectory($id: String!) {
            deleteDirectory(id: $id)
        }
    `;

    public static readonly moveAssetsToAssetDirectory: DocumentNode = gql`
        mutation moveAssetsToAssetDirectory($asset_ids: [String!]!, $directory_id: String) {
            moveAssetsToAssetDirectory(asset_ids: $asset_ids, directory_id: $directory_id)
        }
    `;
}
