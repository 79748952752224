import React from "react";
import { Asset } from "@api/graphql/types";
import { Typography, Divider, Grid, Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { FileSizeUtils } from "@utils/FileSizeUtils";
import { MimeType } from "@utils/MimeType";
import styled from "styled-components";
import { Color } from "@theme/Theme";
import { Edit, Delete, Check, MoveToInbox, Clear, FileCopyRounded } from "@material-ui/icons";
import { Intl } from "@i18n/Intl";

type Props = {
    selected: boolean;
    onClick?: () => void;
    onMove: () => void;
    onUpdate: () => void;
    onDelete: () => void;
    asset: Asset;
};

interface State {
    contextMenuOpened: boolean;
    contextMenuX: number;
    contextMenuY: number;
}

class AssetItem extends React.Component<Props, State> {
    private assetRef: HTMLDivElement | null = null;

    public state: Readonly<State> = {
        contextMenuOpened: false,
        contextMenuX: 0,
        contextMenuY: 0,
    };

    private readonly onOpenContextMenu = (e: React.MouseEvent<HTMLDivElement>): void => {
        e.preventDefault();
        if (e.currentTarget !== this.assetRef) {
            return;
        }

        if (this.state.contextMenuOpened) {
            this.setState({ contextMenuOpened: false }, () => {
                setTimeout(() => {
                    this.setState({
                        contextMenuOpened: true,
                        contextMenuX: e.clientX - 2,
                        contextMenuY: e.clientY - 4,
                    });
                }, 200);
            });
        } else {
            this.setState({
                contextMenuOpened: true,
                contextMenuX: e.clientX - 2,
                contextMenuY: e.clientY - 4,
            });
        }
    };

    private readonly onSelect = (): void => {
        this.setState(
            {
                contextMenuOpened: false,
            },
            () => {
                this.props.onClick && this.props.onClick();
            }
        );
    };

    private readonly onMove = (): void => {
        this.setState(
            {
                contextMenuOpened: false,
            },
            () => {
                this.props.onMove();
            }
        );
    };

    private readonly onUpdate = (): void => {
        this.setState(
            {
                contextMenuOpened: false,
            },
            () => {
                this.props.onUpdate();
            }
        );
    };

    private readonly onDelete = (): void => {
        this.setState(
            {
                contextMenuOpened: false,
            },
            () => {
                this.props.onDelete();
            }
        );
    };

    private readonly copyFileNameToClipboard = (): void => {
        this.setState(
            {
                contextMenuOpened: false,
            },
            () => {
                navigator.clipboard.writeText(this.props.asset.file_name);
            }
        );
    };

    public render(): React.ReactElement {
        const { asset, selected, onClick } = this.props;
        const url = asset.url_with_opts.replace("[OPTS]", "w_250");

        return (
            <>
                <StyledAssetWrapper
                    selected={selected}
                    onClick={onClick}
                    onContextMenu={this.onOpenContextMenu}
                    ref={ref => {
                        this.assetRef = ref;
                    }}
                >
                    <StyledAssetThumbnail src={url} alt={asset.title} />
                    <StyledAssetInfoWrapper>
                        <Typography variant="subtitle1">{asset.title}</Typography>
                        <Divider />
                        <Grid container>
                            <Grid item sm={4}>
                                <Typography variant="body2" align="left">
                                    {FileSizeUtils.formatHumanSize(asset.file_size)}
                                </Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <Typography variant="body2" align="center">
                                    {asset.info.width} x {asset.info.height}
                                </Typography>
                            </Grid>
                            <Grid item sm={2}>
                                <Typography variant="body2" align="right">
                                    {MimeType.extension(asset.info.mime_type)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </StyledAssetInfoWrapper>
                </StyledAssetWrapper>

                <Menu
                    keepMounted={true}
                    open={this.state.contextMenuOpened}
                    onClose={() => this.setState({ contextMenuOpened: false })}
                    anchorReference="anchorPosition"
                    anchorPosition={{
                        left: this.state.contextMenuX,
                        top: this.state.contextMenuY,
                    }}
                >
                    <MenuItem onClick={() => this.onSelect()}>
                        {selected ? (
                            <>
                                <ListItemIcon>
                                    <Clear />
                                </ListItemIcon>
                                <ListItemText>
                                    {Intl.formatMessage({ id: "components.assetContext.unselect" })}
                                </ListItemText>
                            </>
                        ) : (
                            <>
                                <ListItemIcon>
                                    <Check />
                                </ListItemIcon>
                                <ListItemText>
                                    {Intl.formatMessage({ id: "components.assetContext.select" })}
                                </ListItemText>
                            </>
                        )}
                    </MenuItem>
                    <MenuItem onClick={() => this.onMove()}>
                        <ListItemIcon>
                            <MoveToInbox />
                        </ListItemIcon>
                        <ListItemText>{Intl.formatMessage({ id: "components.assetContext.move" })}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => this.onUpdate()}>
                        <ListItemIcon>
                            <Edit />
                        </ListItemIcon>
                        <ListItemText>{Intl.formatMessage({ id: "components.assetContext.edit" })}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => this.copyFileNameToClipboard()}>
                        <ListItemIcon>
                            <FileCopyRounded />
                        </ListItemIcon>
                        <ListItemText>
                            {Intl.formatMessage({ id: "components.assetContext.copyFileName" })}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => this.onDelete()}>
                        <ListItemIcon>
                            <Delete />
                        </ListItemIcon>
                        <ListItemText>{Intl.formatMessage({ id: "components.assetContext.delete" })}</ListItemText>
                    </MenuItem>
                </Menu>
            </>
        );
    }
}

const StyledAssetThumbnail = styled.img`
    width: 100%;
    height: 200px;
    object-fit: contain;
`;
export const StyledAssetInfoWrapper = styled.div`
    padding: 0.5rem 1rem;

    h6 {
        overflow: hidden;
        text-overflow: ellipsis;
        height: 28px;
        white-space: nowrap;
    }
`;

export const StyledAssetWrapper = styled.div<{ selected?: boolean }>`
    border: 1px solid ${Color("grey", 400)};
    border-radius: 6px;
    overflow: hidden;
    transition: all 100ms ease-in-out;
    background-color: ${props => (props.selected ? Color("blue", 50) : "transparent")};
    box-shadow: ${props => (props.selected ? "0px 8px 20px rgba(0, 0, 0, 0.15)" : "none")};
    ${StyledAssetInfoWrapper} {
        color: ${props => (props.selected ? Color("blue", 700) : Color("grey", 800))};
    }

    &:hover {
        cursor: pointer;
    }
`;

export { AssetItem };
