import React from "react";
import { Directory, UpdateDirectoryInput } from "@api/graphql/types";
import { Dialog, DialogTitle, DialogContent, FormControl, TextField, DialogActions, Button } from "@material-ui/core";
import { Intl } from "@i18n/Intl";
import { Api } from "@api/Api";
import { Validator, ValidatorMessage } from "@utils/Validator";
import { Alert } from "@components/alert/Alert";
import { CustomEventList } from "@utils/Events";

type Props = {
    opened: boolean;
    directory: Directory;
    onClose?: () => void;
};

interface State {
    isLoading: boolean;
    input: UpdateDirectoryInput;
    errors: {
        name: ValidatorMessage | null;
    };
}

export class UpdateDirectoryDialog extends React.Component<Props, State> {
    public state: Readonly<State> = UpdateDirectoryDialog.getInitialState(this.props);

    private static readonly getInitialState = (props: Props): State => {
        return {
            isLoading: false,
            input: {
                name: props.directory.name,
                parent_id: props.directory.parent?.id || null,
            },
            errors: {
                name: null,
            },
        };
    };

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.opened !== this.props.opened) {
            this.setState(UpdateDirectoryDialog.getInitialState(this.props));
        }
    }

    private readonly onNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            input: {
                ...this.state.input,
                name: e.currentTarget.value,
            },
            errors: {
                name: Validator.validateNonEmpty(e.currentTarget.value),
            },
        });
    };

    private readonly onSubmit = (): void => {
        if (Validator.validateNonEmpty(this.state.input.name || "")) {
            this.setState({
                errors: {
                    name: Validator.validateNonEmpty(this.state.input.name || ""),
                },
            });
            return;
        }
        this.setState({ isLoading: true }, async () => {
            try {
                await Api.updateDirectory(this.props.directory.id, this.state.input);

                window.dispatchEvent(new CustomEvent(CustomEventList.refreshDirectories));
                Alert.success({ title: Intl.formatMessage({ id: "components.updateDirectoryDialog.success" }) });
                this.props.onClose && this.props.onClose();
            } catch (error) {
                Alert.error({ title: Intl.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    public render(): React.ReactElement {
        const { opened, onClose } = this.props;
        return (
            <Dialog open={opened} onClose={onClose} fullWidth={true} maxWidth={"sm"}>
                <DialogTitle>{Intl.formatMessage({ id: "components.updateDirectoryDialog.title" })}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth={true} margin={"normal"}>
                        <TextField
                            type="text"
                            label={Intl.formatMessage({ id: "components.updateDirectoryDialog.form.name" })}
                            variant="outlined"
                            value={this.state.input.name}
                            onChange={this.onNameChange}
                            error={!!this.state.errors.name}
                            helperText={Intl.formatValidationError(this.state.errors.name)}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary" disabled={this.state.isLoading}>
                        {Intl.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button onClick={this.onSubmit} color="primary" disabled={this.state.isLoading}>
                        {Intl.formatMessage({ id: "common.save" })}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
