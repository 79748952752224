import { ReduxAction } from "@redux/actions";
import { AppActionType } from "@redux/actions/AppActions";
import { Asset, Directory, listAssetVariables } from "@api/graphql/types";
import { Intl } from "@i18n/Intl";
import { Consts } from "@utils/Consts";

export const defaultAppConfiguration: Partial<AppConfigurationType> = {
    maximumSelectableAsset: 0,
    selectButtonTitle: Intl.formatMessage({ id: "components.actionBar.insert" }),
    embedAuthToken: process.env.NODE_ENV === "development" ? process.env.REACT_APP_AUTH_TOKEN : undefined,
    frameless: false,
};

export type AppConfigurationType = {
    embedAuthToken?: string;
    path?: string;
    maximumSelectableAsset?: number;
    selectButtonTitle?: string;
    navbarColor?: string;
    frameless?: boolean;
};

export type AppReducerState = {
    configuration: AppConfigurationType;
    currentDirectory: Directory | null;
    selectedAssets: Asset[];
    assetOptions: listAssetVariables;
    navbarColor?: string;
};

export class AppReducer {
    private static readonly initialState: AppReducerState = {
        configuration: defaultAppConfiguration,
        currentDirectory: null,
        selectedAssets: [],
        assetOptions: {
            filters: {
                directory_id: null,
            },
            first: Consts.ASSET_PAGE_LENGTH,
        },
    };

    public static instance(state: AppReducerState = AppReducer.initialState, action: ReduxAction): AppReducerState {
        switch (action.type) {
            case AppActionType.setCurrentDirectory:
                return {
                    ...state,
                    currentDirectory: action.directory,
                    assetOptions: {
                        ...state.assetOptions,
                        filters: {
                            ...state.assetOptions.filters,
                            directory_id: action.directory?.id || null,
                        },
                    },
                };
            case AppActionType.setAppConfiguration:
                return {
                    ...state,
                    configuration: {
                        ...(action.isUpdate ? { ...state.configuration, ...action.options } : { ...action.options }),
                    },
                };

            case AppActionType.toggleSelectedAsset: {
                const { selectedAssets } = state;
                const maxSelectableAsset = state.configuration.maximumSelectableAsset || 0;
                if (maxSelectableAsset > 1 && selectedAssets.length === maxSelectableAsset) {
                    return {
                        ...state,
                    };
                }
                if (selectedAssets.some((selectedAsset: Asset) => selectedAsset.id === action.asset.id)) {
                    return {
                        ...state,
                        selectedAssets: selectedAssets.filter(
                            (selectedAsset: Asset) => selectedAsset.id !== action.asset.id
                        ),
                    };
                }
                return {
                    ...state,
                    selectedAssets: maxSelectableAsset === 1 ? [action.asset] : [...selectedAssets, action.asset],
                };
            }
            case AppActionType.setSelectedAssets: {
                return {
                    ...state,
                    selectedAssets: action.assets,
                };
            }
            case AppActionType.clearSelectedAssets: {
                return {
                    ...state,
                    selectedAssets: [],
                };
            }
            case AppActionType.setAssetOptions: {
                return {
                    ...state,
                    assetOptions: action.options,
                };
            }
            case AppActionType.clearAssetOptions: {
                return {
                    ...state,
                    assetOptions: AppReducer.initialState.assetOptions,
                };
            }
            case AppActionType.setAuthToken: {
                return {
                    ...state,
                    configuration: {
                        ...state.configuration,
                        embedAuthToken: action.token,
                    },
                };
            }
            default:
                return state;
        }
    }
}
