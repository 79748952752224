import React from "react";
import { Snackbar, Grow } from "@material-ui/core";
import { Alert as AlertElement } from "@material-ui/lab";
import { AlertType } from "@components/alert/AlertTypes";
import { store } from "@redux/store";
import { AlertActions } from "@redux/actions/AlertActions";

interface Props {
    type: AlertType;
    timeout: number;
    message: string;
    visible: boolean;
    onClose: () => void;
}

class Alert extends React.PureComponent<Props> {
    public static success(options: { title: string; timeout?: number; callback?: () => Promise<void> | void }): void {
        store.dispatch(
            AlertActions.show({
                message: options.title,
                timeout: options.timeout || undefined,
                callback: options.callback,
                type: AlertType.success,
            })
        );
    }

    public static info(options: { title: string; timeout?: number; callback?: () => Promise<void> | void }): void {
        store.dispatch(
            AlertActions.show({
                message: options.title,
                timeout: options.timeout || 5000,
                callback: options.callback,
                type: AlertType.info,
            })
        );
    }

    public static error(options: { title: string; timeout?: number; callback?: () => Promise<void> | void }): void {
        store.dispatch(
            AlertActions.show({
                message: options.title,
                timeout: options.timeout || undefined,
                callback: options.callback,
                type: AlertType.error,
            })
        );
    }

    public render(): React.ReactElement {
        return (
            <Snackbar
                open={this.props.visible}
                autoHideDuration={this.props.timeout}
                TransitionComponent={Grow}
                onClose={this.props.onClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
                <AlertElement onClose={this.props.onClose} severity={this.props.type}>
                    {this.props.message}
                </AlertElement>
            </Snackbar>
        );
    }
}

export { Alert };
