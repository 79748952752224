import { Mutations } from "@api/graphql/queries/Mutations";
import { Queries } from "@api/graphql/queries/Queries";
import { GraphQLClient, OnProgress } from "./graphql/GraphQLClient";
import * as GqlTypes from "./graphql/types";
import { ApiError } from "@api/ApiError";
import { Intl } from "@i18n/Intl";

export class Api {
    public static async listDirectory(
        variables: GqlTypes.listDirectoryVariables
    ): Promise<GqlTypes.listDirectory_listDirectory> {
        const response = await GraphQLClient.query<GqlTypes.listDirectory, GqlTypes.listDirectoryVariables>({
            query: Queries.listDirectory,
            variables,
        });

        if (!response.listDirectory) {
            throw new ApiError(Intl.formatMessage({ id: "api.error" }));
        }

        return response.listDirectory;
    }

    public static async getDirectory(directoryId: string): Promise<GqlTypes.Directory> {
        const response = await GraphQLClient.query<GqlTypes.directory, GqlTypes.directoryVariables>({
            query: Queries.directory,
            variables: {
                id: directoryId,
            },
        });

        if (!response.directory) {
            throw new ApiError(Intl.formatMessage({ id: "api.error.notFound" }));
        }

        return response.directory;
    }

    public static async createDirectory(input: GqlTypes.CreateDirectoryInput): Promise<GqlTypes.Directory> {
        const response = await GraphQLClient.mutate<GqlTypes.createDirectory, GqlTypes.createDirectoryVariables>({
            mutation: Mutations.createDirectory,
            variables: {
                input,
            },
        });

        return response.createDirectory;
    }

    public static async updateDirectory(id: string, input: GqlTypes.UpdateDirectoryInput): Promise<GqlTypes.Directory> {
        const response = await GraphQLClient.mutate<GqlTypes.updateDirectory, GqlTypes.updateDirectoryVariables>({
            mutation: Mutations.updateDirectory,
            variables: {
                id,
                input,
            },
        });

        return response.updateDirectory;
    }

    public static async deleteDirectory(id: string): Promise<boolean> {
        const response = await GraphQLClient.mutate<GqlTypes.deleteDirectory, GqlTypes.deleteDirectoryVariables>({
            mutation: Mutations.deleteDirectory,
            variables: {
                id,
            },
        });

        return response.deleteDirectory;
    }

    public static async listAsset(variables: GqlTypes.listAssetVariables): Promise<GqlTypes.listAsset_listAsset> {
        const response = await GraphQLClient.query<GqlTypes.listAsset, GqlTypes.listAssetVariables>({
            query: Queries.listAsset,
            variables,
        });

        if (!response.listAsset) {
            throw new ApiError(Intl.formatMessage({ id: "api.error" }));
        }

        return response.listAsset;
    }

    public static async updateAsset(id: string, title: string): Promise<GqlTypes.Asset> {
        const response = await GraphQLClient.mutate<GqlTypes.updateAsset, GqlTypes.updateAssetVariables>({
            mutation: Mutations.updateAsset,
            variables: {
                id,
                title,
            },
        });

        return response.updateAsset;
    }

    public static async uploadAsset(
        title: string,
        file: File,
        onProgress: OnProgress,
        directoryId?: string | null
    ): Promise<GqlTypes.Asset> {
        const response = await GraphQLClient.upload<GqlTypes.uploadAsset, GqlTypes.uploadAssetVariables>({
            mutation: Mutations.uploadAsset,
            variables: {
                directory_id: directoryId,
                title,
                file,
            },
            files: {
                file,
            },
            onProgress,
        });

        return response.uploadAsset;
    }

    public static async deleteAsset(id: string): Promise<boolean> {
        const response = await GraphQLClient.mutate<GqlTypes.deleteAsset, GqlTypes.deleteAssetVariables>({
            mutation: Mutations.deleteAsset,
            variables: {
                id,
            },
        });

        return response.deleteAsset;
    }

    public static async deleteAssets(ids: string[]): Promise<boolean> {
        const response = await GraphQLClient.mutate<GqlTypes.deleteAssets, GqlTypes.deleteAssetsVariables>({
            mutation: Mutations.deleteAssets,
            variables: {
                ids,
            },
        });

        return response.deleteAssets;
    }

    public static async moveAssetsToAssetDirectory(directoryId: string, assetIds: string[]): Promise<boolean> {
        const response = await GraphQLClient.mutate<
            GqlTypes.moveAssetsToAssetDirectory,
            GqlTypes.moveAssetsToAssetDirectoryVariables
        >({
            mutation: Mutations.moveAssetsToAssetDirectory,
            variables: {
                directory_id: directoryId,
                asset_ids: assetIds,
            },
        });

        return response.moveAssetsToAssetDirectory;
    }
}
