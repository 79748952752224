import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";
import { Asset } from "@api/graphql/types";
import { Intl } from "@i18n/Intl";
import { Api } from "@api/Api";
import { CustomEventList } from "@utils/Events";
import { Alert } from "@components/alert/Alert";
import { MimeType } from "@utils/MimeType";

type Props = {
    opened: boolean;
    asset: Asset;
    onClose?: () => void;
};

interface State {
    isLoading: boolean;
}

export class DeleteAssetDialog extends React.PureComponent<Props> {
    public state: Readonly<State> = {
        isLoading: false,
    };

    private readonly onSubmit = (): void => {
        this.setState({ isLoading: true }, async () => {
            try {
                await Api.deleteAsset(this.props.asset.id);

                window.dispatchEvent(new CustomEvent(CustomEventList.refreshAssets));
                Alert.success({ title: Intl.formatMessage({ id: "components.deleteAssetDialog.success" }) });
                this.props.onClose && this.props.onClose();
            } catch (error) {
                Alert.error({ title: Intl.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    public render(): React.ReactElement {
        const { opened, onClose, asset } = this.props;
        return (
            <Dialog open={opened} onClose={onClose} fullWidth={true} maxWidth={"sm"}>
                <DialogTitle>{Intl.formatMessage({ id: "components.deleteAssetDialog.title" })}</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">
                        {Intl.formatHTMLMessage(
                            { id: "components.deleteAssetDialog.description" },
                            { name: `${asset.title}.${MimeType.extension(asset.info.mime_type)}` }
                        )}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary" disabled={this.state.isLoading}>
                        {Intl.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button onClick={this.onSubmit} color="primary" disabled={this.state.isLoading}>
                        {Intl.formatMessage({ id: "common.delete" })}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
