import React from "react";
import { connect, MapStateToProps, DispatchProp } from "react-redux";
import { ApplicationState } from "@redux/reducers";
import { Dialog, DialogTitle, TextField, Container, FormControl, Button } from "@material-ui/core";
import { Intl } from "@i18n/Intl";
import styled from "styled-components";
import { AppActions } from "@redux/actions/AppActions";
import { Redirect } from "react-router-dom";
import { Paths } from "@pages/paths";

interface ComponentProps {}

interface ReduxProps {
    token?: string;
}

type Props = ComponentProps & ReduxProps & DispatchProp;

interface State {
    token: string;
}

class LoginPageComponent extends React.Component<Props, State> {
    public state: Readonly<State> = {
        token: "",
    };

    private readonly onTokenChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            token: e.currentTarget.value,
        });
    };

    private readonly onAuth = (): void => {
        if (this.state.token.length < 1) {
            return;
        }
        this.props.dispatch(AppActions.setAppConfiguration({ embedAuthToken: this.state.token }, true));
    };

    public render(): React.ReactElement {
        if (this.props.token) {
            return <Redirect to={Paths.Root} />;
        }

        return (
            <Dialog open={true} fullWidth={true}>
                <DialogTitle>{Intl.formatMessage({ id: "components.login.title" })}</DialogTitle>
                <StyledContainer>
                    <FormControl fullWidth={true} margin={"normal"}>
                        <TextField
                            type="text"
                            label={Intl.formatMessage({ id: "components.login.token" })}
                            variant="outlined"
                            onChange={this.onTokenChange}
                            value={this.state.token}
                            error={this.state.token.length < 1}
                        />
                    </FormControl>
                    <Button
                        fullWidth={true}
                        variant="contained"
                        color="primary"
                        disabled={this.state.token.length < 1}
                        onClick={() => this.onAuth()}
                    >
                        {Intl.formatMessage({ id: "components.login.auth" })}
                    </Button>
                </StyledContainer>
            </Dialog>
        );
    }
}

const StyledContainer = styled(Container)`
    padding: 2rem;
`;

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (
    state: ApplicationState
): ReduxProps => {
    return {
        token: state.app.configuration.embedAuthToken,
    };
};

const LoginPage = connect(mapStateToProps)(LoginPageComponent);

export { LoginPage };
