export type FieldError = { name: string; message: string };

export enum ApiErrorMessage {
    unauthenticated = "Unauthenticated.",
}

export class ApiError extends Error {
    constructor(message: string, public fieldErrors: FieldError[] = []) {
        super(message);
    }

    public hasFieldError(): boolean {
        return this.fieldErrors.length > 0;
    }
}
