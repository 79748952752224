export class Paths {
    public static readonly Root: string = "/";
    public static readonly Login: string = "/login";
    public static readonly Directory = (directoryId: string): string => {
        return `/directory/${directoryId}`;
    };
    public static readonly Embedded: string = "/embedded";
}

export class AppPath {
    public static readonly Root: string = Paths.Root;
    public static readonly Directory: string = Paths.Directory(":directoryId");
    public static readonly Login: string = Paths.Login;
    public static readonly Embedded: string = Paths.Embedded;
}
