import React from "react";
import { Switch, Route } from "react-router-dom";
import { AppPath } from "@pages/paths";
import { DirectoryPage } from "@pages/Directory/DirectoryPage";
import { LoginPage } from "@pages/Login/LoginPage";
import { Main } from "@components/Main";
import { AlertContainer } from "@components/alert/AlertContainer";
import { EmbeddedStartPage } from "@pages/Embedded/EmbeddedPage";

export const App = () => {
    return (
        <>
            <Main>
                <Switch>
                    <Route exact path={AppPath.Root} component={DirectoryPage} />
                    <Route exact path={AppPath.Login} component={LoginPage} />
                    <Route exact path={AppPath.Directory} component={DirectoryPage} />
                    <Route exact path={AppPath.Embedded} component={EmbeddedStartPage} />
                </Switch>
            </Main>
            <AlertContainer />
        </>
    );
};
