import gql from "graphql-tag";
import { DocumentNode } from "graphql";

export class Fragments {
    public static readonly assetInfo: DocumentNode = gql`
        fragment AssetInfo on AssetInfo {
            original_file_name
            mime_type
            etag
            width
            height
        }
    `;

    public static readonly asset: DocumentNode = gql`
        fragment Asset on Asset {
            id
            title
            file_name
            file_size
            url
            url_with_opts
            info {
                ...AssetInfo
            }
            created_at
            updated_at
        }
        ${Fragments.assetInfo}
    `;

    public static readonly directoryItem: DocumentNode = gql`
        fragment DirectoryItem on Directory {
            id
            name
            created_at
            updated_at
        }
    `;

    public static readonly directory: DocumentNode = gql`
        fragment Directory on Directory {
            id
            name
            parent {
                ...DirectoryItem
            }
            children {
                ...DirectoryItem
            }
            is_deletable
            created_at
            updated_at
        }
        ${Fragments.directoryItem}
    `;

    public static readonly paginatorInfo: DocumentNode = gql`
        fragment PaginatorInfo on PaginatorInfo {
            count
            currentPage
            firstItem
            hasMorePages
            lastItem
            lastPage
            perPage
            total
        }
    `;
}
