import { Reducer, combineReducers } from "redux";

import { AlertReducer, AlertReducerState } from "./AlertReducer";
import { AppReducerState, AppReducer } from "@redux/reducers/AppReducer";

export interface ApplicationState {
    alerts: AlertReducerState;
    app: AppReducerState;
}

export const rootReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
    alerts: AlertReducer.instance,
    app: AppReducer.instance,
});
