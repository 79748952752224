import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "@redux/store";
import { ThemeProvider } from "styled-components";
import { theme } from "@theme/Theme";
import { GlobalStyle } from "@theme/GlobalStyle";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <>
                    <GlobalStyle />

                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
