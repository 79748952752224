import React from "react";
import { Directories } from "@components/directory/Directories";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { DispatchProp, connect, MapStateToProps } from "react-redux";
import { AppActions } from "@redux/actions/AppActions";
import styled from "styled-components";
import { CreateDirectoryDialog } from "@components/dialogs/CreateDirectoryDialog";
import { Assets } from "@components/assets/Assets";
import { batchActions } from "redux-batched-actions";
import {
    Breadcrumbs,
    Link,
    Typography,
    Button,
    Grid,
    FormControl,
    TextField,
    Divider,
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import { Paths } from "@pages/paths";
import { Home, CreateNewFolder, CloudUpload, Search, CloseRounded } from "@material-ui/icons";
import { Directory, listAssetVariables } from "@api/graphql/types";
import { Api } from "@api/Api";
import { Alert } from "@components/alert/Alert";
import { Intl } from "@i18n/Intl";
import { ApplicationState } from "@redux/reducers";
import * as _ from "lodash";
import { UploadAssetDialog } from "@components/dialogs/UploadAssetDialog";
import { FloatingBar } from "@components/FloatingBar";
import isNil from "lodash/isNil";
import { sendPMEvent, OutgoingEvent } from "@utils/EmbeddedUtils";

interface RouteProps {
    directoryId?: string;
}

interface ComponentProps {}

interface ReduxProps {
    options: listAssetVariables;
    token?: string;
    isEmbeddedView: boolean;
    maxSelectableAsset: number;
    frameless: boolean;
}

type Props = ComponentProps & RouteComponentProps<RouteProps> & ReduxProps & DispatchProp;

interface State {
    isLoading: boolean;
    currentDirectory: Directory | null;
    createDirectoryDialogOpened: boolean;
    uploadAssetDialogOpened: boolean;
    search: string;
}

class DirectoryPageComponent extends React.Component<Props, State> {
    private contextRef: HTMLDivElement | null = null;
    private directoriesRef: HTMLDivElement | null = null;
    private assetsRef: HTMLDivElement | null = null;

    public state: Readonly<State> = {
        isLoading: true,
        currentDirectory: null,
        createDirectoryDialogOpened: false,
        uploadAssetDialogOpened: false,
        search: this.props.options.filters?.search || "",
    };

    public componentDidMount() {
        this.setCurrentDirectory();
    }

    public componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.match.params.directoryId !== this.props.match.params.directoryId) {
            this.setCurrentDirectory();
            this.props.dispatch(batchActions([AppActions.clearSelectedAssets()]));
        }
    }

    private readonly setCurrentDirectory = (): void => {
        if (!this.props.match.params.directoryId) {
            this.setState({ currentDirectory: null }, () => {
                this.props.dispatch(AppActions.setCurrentDirectory(null));
            });
            return;
        }
        this.setState({ isLoading: true }, async () => {
            try {
                const response = await Api.getDirectory(this.props.match.params.directoryId!);

                this.setState(
                    {
                        isLoading: false,
                        currentDirectory: response,
                    },
                    () => {
                        this.props.dispatch(AppActions.setCurrentDirectory(response));
                    }
                );
            } catch (error) {
                Alert.error({ title: Intl.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    private readonly toggleCreateDirectoryDialog = (): void => {
        this.setState({
            createDirectoryDialogOpened: !this.state.createDirectoryDialogOpened,
        });
    };

    private readonly toggleUploadAssetDialog = (): void => {
        this.setState({
            uploadAssetDialogOpened: !this.state.uploadAssetDialogOpened,
        });
    };

    private readonly onClickOutside = (e: React.MouseEvent<HTMLDivElement>): void => {
        if (e.target !== this.contextRef) {
            return;
        }

        this.props.dispatch(AppActions.clearSelectedAssets());
    };

    private readonly setAssetSearch = _.debounce(() => {
        this.props.dispatch(
            AppActions.setAssetOptions({
                ...this.props.options,
                filters: {
                    ...this.props.options.filters,
                    search: this.state.search.length ? this.state.search : undefined,
                },
            })
        );
    }, 1000);

    private readonly onSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState(
            {
                search: e.currentTarget.value,
            },
            this.setAssetSearch
        );
    };

    public render(): React.ReactElement {
        const { createDirectoryDialogOpened, currentDirectory, uploadAssetDialogOpened } = this.state;

        if (!this.props.token) {
            return <Redirect to={Paths.Login} />;
        }

        return (
            <ContextMenu
                onClick={this.onClickOutside}
                ref={ref => {
                    this.contextRef = ref;
                }}
            >
                <Grid container spacing={3} justify={"space-between"} alignItems={"center"}>
                    <Grid item alignItems="center">
                        <Breadcrumbs>
                            <Link
                                color="inherit"
                                href={Paths.Root}
                                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                                    e.preventDefault();
                                    this.props.history.push(Paths.Root);
                                }}
                            >
                                <Home />
                            </Link>
                            {currentDirectory && currentDirectory.parent && (
                                <Typography color="textPrimary">...</Typography>
                            )}
                            {currentDirectory && currentDirectory.parent && (
                                <Link
                                    color="inherit"
                                    href={Paths.Directory(currentDirectory.parent!.id)}
                                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                                        e.preventDefault();
                                        this.setCurrentDirectory();
                                        this.props.history.push(Paths.Directory(currentDirectory.parent!.id));
                                    }}
                                >
                                    {currentDirectory.parent.name}
                                </Link>
                            )}

                            {currentDirectory && <Typography color="textPrimary">{currentDirectory.name}</Typography>}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={3} alignItems={"center"}>
                            <Grid item>
                                <FormControl>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        value={this.state.search}
                                        onChange={this.onSearchChange}
                                        placeholder={Intl.formatMessage({ id: "common.searchPlaceholder" })}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Button startIcon={<CreateNewFolder />} onClick={this.toggleCreateDirectoryDialog}>
                                    {Intl.formatMessage({ id: "common.createDirectory" })}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button startIcon={<CloudUpload />} onClick={this.toggleUploadAssetDialog}>
                                    {Intl.formatMessage({ id: "common.upload" })}
                                </Button>
                            </Grid>
                            {this.props.isEmbeddedView && !this.props.frameless && (
                                <Grid item>
                                    <IconButton
                                        onClick={() => {
                                            sendPMEvent(OutgoingEvent.Close);
                                        }}
                                    >
                                        <CloseRounded />
                                    </IconButton>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <StyledDivider />
                <Directories
                    innerRef={ref => {
                        this.directoriesRef = ref;
                    }}
                />
                <Assets
                    innerRef={ref => {
                        this.assetsRef = ref;
                    }}
                />
                <CreateDirectoryDialog
                    opened={createDirectoryDialogOpened}
                    parentId={this.props.match.params.directoryId}
                    onClose={() => this.toggleCreateDirectoryDialog()}
                />
                <UploadAssetDialog opened={uploadAssetDialogOpened} onClose={this.toggleUploadAssetDialog} />
                <FloatingBar
                    isEmbeddedView={this.props.isEmbeddedView}
                    maxSelectableAsset={this.props.maxSelectableAsset}
                    frameless={this.props.frameless}
                />
            </ContextMenu>
        );
    }
}

const ContextMenu = styled.div`
    height: 100%;
    padding: 3rem;
    overflow-y: scroll;
`;

const StyledDivider = styled(Divider)`
    margin: 1rem 0 !important;
`;

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (
    state: ApplicationState
): ReduxProps => {
    return {
        options: state.app.assetOptions,
        token: state.app.configuration.embedAuthToken,
        isEmbeddedView: !!state.app.configuration.embedAuthToken,
        maxSelectableAsset: state.app.configuration.maximumSelectableAsset || 0,
        frameless: !!state.app.configuration.frameless,
    };
};

const DirectoryPage = withRouter(connect(mapStateToProps)(DirectoryPageComponent));

export { DirectoryPage };
