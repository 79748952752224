import { css, createGlobalStyle } from "styled-components";

// https://accessibility.18f.gov/hidden-content/
export const showForSr = css`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const GlobalStyle = createGlobalStyle`
    html {
        box-sizing: border-box;
    }
    
    body {
        margin: 0;
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    :focus {
        outline: 0;
        /* outline: 4px solid red; */
    }
`;
