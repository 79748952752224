import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import styled from "styled-components";
import { Color } from "@theme/Theme";
import { Intl } from "@i18n/Intl";
import { DirectoryItem } from "@components/directory/DirectoryItem";
import { Api } from "@api/Api";
import { Alert } from "@components/alert/Alert";
import { Directory } from "@api/graphql/types";
import { Skeleton } from "@material-ui/lab";
import { MapStateToProps, connect } from "react-redux";
import { ApplicationState } from "@redux/reducers";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Paths } from "@pages/paths";
import { CustomEventList } from "@utils/Events";
import { UpdateDirectoryDialog } from "@components/dialogs/UpdateDirectoryDialog";
import { DeleteDirectoryDialog } from "@components/dialogs/DeleteDirectoryDialog";

interface ComponentProps {
    innerRef?: (ref: HTMLDivElement | null) => void;
}

interface ReduxProps {
    currentDirectory: Directory | null;
}

type Props = ComponentProps & ReduxProps & RouteComponentProps;

interface State {
    isLoading: boolean;
    directories: Directory[];
    updateDirectoryModalOpened: boolean;
    updateDirectoryModalDirectory: Directory | null;
    deleteDirectoryModalOpened: boolean;
    deleteDirectoryModalDirectory: Directory | null;
}

class DirectoriesComponent extends React.Component<Props, State> {
    public state: Readonly<State> = {
        isLoading: true,
        directories: [],
        updateDirectoryModalOpened: false,
        updateDirectoryModalDirectory: null,
        deleteDirectoryModalOpened: false,
        deleteDirectoryModalDirectory: null,
    };

    public componentDidMount(): void {
        this.fetchDirectories(this.props.currentDirectory?.id || null);
        window.addEventListener(CustomEventList.refreshDirectories, () =>
            this.fetchDirectories(this.props.currentDirectory?.id || null)
        );
    }

    public componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.currentDirectory !== this.props.currentDirectory) {
            this.fetchDirectories(this.props.currentDirectory?.id || null);
        }
    }

    public componentWillUnmount(): void {
        window.removeEventListener(CustomEventList.refreshDirectories, () =>
            this.fetchDirectories(this.props.currentDirectory?.id || null)
        );
    }

    private readonly fetchDirectories = (directoryId: string | null = null): void => {
        this.setState({ isLoading: true }, async () => {
            try {
                const response = await Api.listDirectory({
                    filters: {
                        parent_id: directoryId,
                    },
                    first: 100,
                });
                this.setState({
                    isLoading: false,
                    directories: response.data,
                });
            } catch (error) {
                Alert.error({ title: Intl.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    private readonly renderLoadingDirectories = (): React.ReactElement[] => {
        return new Array(8).fill(1).map((_value, key) => {
            return (
                <Grid item xs={12} sm={4} md={2} key={`directoryLoading-${key}`}>
                    <Skeleton>
                        <StyledLoadingDirectory />
                    </Skeleton>
                </Grid>
            );
        });
    };

    private readonly renderDirectories = (directories: Directory[]): React.ReactElement[] => {
        return directories.map(
            (directory: Directory, key: number): React.ReactElement => {
                return (
                    <Grid item xs={12} sm={4} md={2} key={`directory-${directory.id}-${key}`}>
                        <DirectoryItem
                            title={directory.name}
                            deletable={directory.is_deletable}
                            onClick={() => this.props.history.push(Paths.Directory(directory.id))}
                            onUpdate={() => this.toggleDirectoryUpdate(directory)}
                            onDelete={() => this.toggleDirectoryDelete(directory)}
                        />
                    </Grid>
                );
            }
        );
    };

    private readonly toggleDirectoryUpdate = (directory?: Directory): void => {
        this.setState({
            updateDirectoryModalOpened: !!directory,
            updateDirectoryModalDirectory: directory || null,
        });
    };

    private readonly toggleDirectoryDelete = (directory?: Directory): void => {
        this.setState({
            deleteDirectoryModalOpened: !!directory,
            deleteDirectoryModalDirectory: directory || null,
        });
    };

    public render(): React.ReactElement {
        const {
            isLoading,
            directories,
            updateDirectoryModalOpened,
            updateDirectoryModalDirectory,
            deleteDirectoryModalOpened,
            deleteDirectoryModalDirectory,
        } = this.state;
        return (
            <>
                {directories.length > 0 && (
                    <Box display="block">
                        <Typography variant="subtitle1">
                            <StyledTitle>{Intl.formatMessage({ id: "components.directories.title" })}</StyledTitle>
                        </Typography>
                    </Box>
                )}
                <Grid container spacing={3} ref={this.props.innerRef}>
                    {isLoading ? this.renderLoadingDirectories() : this.renderDirectories(directories)}
                </Grid>
                {updateDirectoryModalOpened && !!updateDirectoryModalDirectory && (
                    <UpdateDirectoryDialog
                        opened={updateDirectoryModalOpened}
                        directory={updateDirectoryModalDirectory}
                        onClose={() => this.toggleDirectoryUpdate()}
                    />
                )}
                {deleteDirectoryModalOpened && !!deleteDirectoryModalDirectory && (
                    <DeleteDirectoryDialog
                        opened={deleteDirectoryModalOpened}
                        directory={deleteDirectoryModalDirectory}
                        onClose={() => this.toggleDirectoryDelete()}
                    />
                )}
            </>
        );
    }
}

const StyledTitle = styled.span`
    margin-left: 16px;
    color: ${Color("grey", 600)};
`;

const StyledLoadingDirectory = styled.div`
    width: 300px;
    min-height: 60px;
    border-radius: 6px;
`;

const mapStateToProps: MapStateToProps<ReduxProps, ComponentProps, ApplicationState> = (
    state: ApplicationState
): ReduxProps => {
    return {
        currentDirectory: state.app.currentDirectory,
    };
};

const Directories = withRouter(connect(mapStateToProps)(DirectoriesComponent));

export { Directories };
