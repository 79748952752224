import { red, grey, indigo, green, blue } from "@material-ui/core/colors";

export const hexToRGBA = (h: string, opacity = 1): string => {
    let r = "";
    let g = "";
    let b = "";

    if (h.length === 4) {
        r = `0x${h[1]}${h[1]}`;
        g = `0x${h[2]}${h[2]}`;
        b = `0x${h[3]}${h[3]}`;
    } else if (h.length === 7) {
        r = `0x${h[1]}${h[2]}`;
        g = `0x${h[3]}${h[4]}`;
        b = `0x${h[5]}${h[6]}`;
    }

    return `rgba(${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}, ${opacity})`;
};

type ColorTypes = "black" | "grey" | "red" | "indigo" | "green" | "blue" | "white";
type ColorShade = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

// Note: X = extra, D = dark, L = light
export const Color = (type: ColorTypes, shade?: ColorShade): string => {
    let currentShade: ColorShade | undefined = shade;
    if (!currentShade) {
        currentShade = 500;
    }
    switch (type) {
        case "black":
            return "#000000";
        case "grey":
            return grey[currentShade!];
        case "red":
            return red[currentShade!];
        case "indigo":
            return indigo[currentShade!];
        case "green":
            return green[currentShade!];
        case "blue":
            return blue[currentShade!];
        case "white":
            return "#FFFFFF";
    }
};

export const theme = {};
