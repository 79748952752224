import React from "react";
import { Asset, Directory } from "@api/graphql/types";
import { Dialog, DialogTitle, DialogContent, Typography, Divider, DialogActions, Button } from "@material-ui/core";
import { Intl } from "@i18n/Intl";
import { DirectoryList } from "@components/directory/DirectoryList";
import styled from "styled-components";
import { Api } from "@api/Api";
import { CustomEventList } from "@utils/Events";
import { Alert } from "@components/alert/Alert";

type Props = {
    opened: boolean;
    assets: Asset[];
    onClose?: () => void;
};

interface State {
    isLoading: boolean;
    selectedDirectory: Directory | null;
}

export class MoveAssetsDialog extends React.Component<Props, State> {
    public state: Readonly<State> = {
        isLoading: false,
        selectedDirectory: null,
    };

    private readonly toggleSelectedDirectory = (directory: Directory): void => {
        if (this.state.selectedDirectory === directory) {
            this.setState({ selectedDirectory: null });
            return;
        }
        this.setState({ selectedDirectory: directory });
    };

    private readonly onSubmit = () => {
        if (!this.state.selectedDirectory) {
            return;
        }
        this.setState({ isLoading: true }, async () => {
            try {
                await Api.moveAssetsToAssetDirectory(
                    this.state.selectedDirectory!.id,
                    this.props.assets.map(asset => asset.id)
                );

                window.dispatchEvent(new CustomEvent(CustomEventList.refreshAssets));
                Alert.success({ title: Intl.formatMessage({ id: "components.moveAssetsDialog.success" }) });
                this.props.onClose && this.props.onClose();
            } catch (error) {
                Alert.error({ title: Intl.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    public render(): React.ReactElement {
        const { opened, onClose, assets } = this.props;
        return (
            <Dialog open={opened} onClose={onClose} fullWidth={true} maxWidth={"sm"}>
                <DialogTitle>{Intl.formatMessage({ id: "components.moveAssetsDialog.title" })}</DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1">
                        {Intl.formatMessage({ id: "components.moveAssetsDialog.description" })}
                    </Typography>
                    {assets.map((asset, key) => {
                        return (
                            <Typography variant={"subtitle2"} key={`assetTitle-${asset.id}-${key}`}>
                                <b>{asset.title}</b>
                            </Typography>
                        );
                    })}
                    <StyledDivider />
                    <ScrollableDirectoryListWrapper>
                        <DirectoryList
                            onClick={this.toggleSelectedDirectory}
                            selected={this.state.selectedDirectory?.id}
                        />
                    </ScrollableDirectoryListWrapper>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary" disabled={this.state.isLoading}>
                        {Intl.formatMessage({ id: "common.cancel" })}
                    </Button>
                    <Button
                        onClick={this.onSubmit}
                        color="primary"
                        disabled={this.state.isLoading || !this.state.selectedDirectory}
                    >
                        {Intl.formatMessage({ id: "common.save" })}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const StyledDivider = styled(Divider)`
    margin: 1rem 0 !important;
`;

const ScrollableDirectoryListWrapper = styled.div`
    max-height: 250px;
    overflow-y: scroll;
`;
