enum ValidatorMessage {
    required = "required",
}

class ValidatorConstants {
    public static readonly ACCEPTED_IMAGES: string[] = ["image/jpeg", "image/jpg", "image/png"];
}

/**
 * Class for validator functions, returns null or a ValidatorMessage
 * Return value can processed by IntlHelper
 */
class Validator {
    public static validateNonEmpty(value: string | string[] | null): ValidatorMessage | null {
        if ((Array.isArray(value) && value.length === 0) || value === "" || value === null) {
            return ValidatorMessage.required;
        }
        return null;
    }
}

export { Validator, ValidatorConstants, ValidatorMessage };
