import React from "react";
import { AlertOptions, AlertType } from "@components/alert/AlertTypes";
import { DispatchProp, MapStateToProps, connect } from "react-redux";
import { ApplicationState } from "@redux/reducers";
import { AlertActions } from "@redux/actions/AlertActions";
import { Alert } from "@components/alert/Alert";

interface ReduxProps {
    alerts: AlertOptions[];
}

type Props = ReduxProps & DispatchProp;

interface State {
    lastAlertOptions: AlertOptions | null;
    currentAlertOptions: AlertOptions | null;
    isShowing: boolean;
}

class AlertContainerComponent extends React.Component<Props, State> {
    private static readonly HIDE_ANIMATION_LENGTH: number = 1000;
    private static readonly DEFAULT_SHOW_TIMEOUT: number = 4000;

    public readonly state: State = {
        lastAlertOptions: null,
        currentAlertOptions: null,
        isShowing: false,
    };

    private showingTimeout: number | null = null;
    private removeTimeout: number | null = null;

    public componentWillUnmount(): void {
        if (this.showingTimeout) {
            clearTimeout(this.showingTimeout);
        }

        if (this.removeTimeout) {
            clearTimeout(this.removeTimeout);
        }
    }

    public componentDidMount() {
        this.loadQueue(this.props);
    }

    public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        if (prevProps.alerts !== this.props.alerts && !prevState.isShowing && prevState.currentAlertOptions === null) {
            this.loadQueue(this.props);
        }
    }

    private loadQueue(props: Props): void {
        if (props.alerts.length > 0) {
            const currentAlertOptions: AlertOptions = props.alerts[0];
            this.setState({ currentAlertOptions, isShowing: true }, (): void => {
                this.setShowingTimeout(currentAlertOptions.timeout || AlertContainerComponent.DEFAULT_SHOW_TIMEOUT);
            });
        }
    }

    private setShowingTimeout(timeout: number): void {
        this.showingTimeout = window.setTimeout(() => {
            this.setState({ isShowing: false }, () => {
                this.setRemoveTimeout();
            });
        }, timeout);
    }

    private setRemoveTimeout(): void {
        this.removeTimeout = window.setTimeout(() => {
            const { currentAlertOptions } = this.state;
            this.setState({ currentAlertOptions: null, lastAlertOptions: currentAlertOptions }, () => {
                this.props.dispatch(AlertActions.hide());

                if (currentAlertOptions && currentAlertOptions.callback) {
                    currentAlertOptions.callback();
                }
            });
        }, AlertContainerComponent.HIDE_ANIMATION_LENGTH);
    }

    public onClose = (): void => {
        if (this.showingTimeout) {
            clearTimeout(this.showingTimeout);
        }

        if (this.removeTimeout) {
            clearTimeout(this.removeTimeout);
        }

        const { currentAlertOptions } = this.state;
        this.setState({ currentAlertOptions: null, lastAlertOptions: currentAlertOptions, isShowing: false }, () => {
            this.props.dispatch(AlertActions.hide());
            if (currentAlertOptions && currentAlertOptions.callback) {
                currentAlertOptions.callback();
            }
        });
    };

    public render(): JSX.Element {
        const { currentAlertOptions, lastAlertOptions } = this.state;

        const message: string = currentAlertOptions
            ? currentAlertOptions.message
            : lastAlertOptions
            ? lastAlertOptions.message
            : "";
        const timeout: number | undefined = currentAlertOptions
            ? currentAlertOptions.timeout
            : lastAlertOptions
            ? lastAlertOptions.timeout
            : AlertContainerComponent.DEFAULT_SHOW_TIMEOUT;
        const type: AlertType = currentAlertOptions
            ? currentAlertOptions.type
            : lastAlertOptions
            ? lastAlertOptions.type
            : AlertType.info;
        return (
            <Alert
                message={message}
                visible={!!currentAlertOptions}
                timeout={timeout || AlertContainerComponent.DEFAULT_SHOW_TIMEOUT}
                type={type}
                onClose={this.onClose}
            />
        );
    }
}

const mapStateToProps: MapStateToProps<ReduxProps, {}, ApplicationState> = (state: ApplicationState): ReduxProps => {
    return { alerts: state.alerts };
};

export const AlertContainer = connect(mapStateToProps)(AlertContainerComponent);
