import React from "react";
import { Directory } from "@api/graphql/types";
import { Api } from "@api/Api";
import { Alert } from "@components/alert/Alert";
import { Intl } from "@i18n/Intl";
import { List, Grid, CircularProgress } from "@material-ui/core";
import { DirectoryListItem } from "@components/directory/DirectoryListItem";

type Props = {
    onClick?: (directory: Directory) => void;
    selected?: string;
    parentId?: string;
};

interface State {
    isLoading: boolean;
    directories: Directory[];
}

export class DirectoryList extends React.Component<Props, State> {
    public state: Readonly<State> = {
        isLoading: true,
        directories: [],
    };

    public componentDidMount(): void {
        this.fetchDirectories();
    }

    private readonly fetchDirectories = (): void => {
        this.setState({ isLoading: true }, async () => {
            try {
                const response = await Api.listDirectory(
                    this.props.parentId
                        ? {
                              filters: {
                                  parent_id: this.props.parentId || null,
                              },
                              first: 100,
                          }
                        : { first: 100 }
                );

                this.setState({
                    isLoading: false,
                    directories: response.data,
                });
            } catch (error) {
                Alert.error({ title: Intl.getMessageFromError(error) });
                this.setState({ isLoading: false });
            }
        });
    };

    private readonly renderDirectories = (directories: Directory[]): React.ReactElement[] => {
        return directories.map(
            (directory: Directory, key: number): React.ReactElement => {
                return (
                    <DirectoryListItem
                        directory={directory}
                        key={`directoryList-${directory.id}-${key}`}
                        onClick={this.props.onClick}
                        selected={this.props.selected}
                    />
                );
            }
        );
    };

    public render() {
        const { isLoading, directories } = this.state;
        if (isLoading) {
            return (
                <Grid container alignItems={"center"} justify={"center"}>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            );
        }
        return <List>{this.renderDirectories(directories)}</List>;
    }
}
